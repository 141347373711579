<template>
  <b-card title="Redeem History List">
    <b-col cols="12 row text-left" class="p-0 mt-1 mb-1">
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal-redeem-history
        >
          Export Redeem History
        </b-button>
      </b-col>
    </b-col>
    <b-row class="mb-2 justify-content-between">
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="status">Status:</label>
          <v-select
            id="status"
            label="name"
            v-model="filter.status"
            :options="status"
            placeholder="-- Pilih --"
            :reduce="(option) => option.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="start-time">Start Time:</label>
          <flat-pickr
            id="start-time"
            v-model="filter.start_time"
            class="form-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              time_24hr: true,
            }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="end-time">End Time:</label>
          <flat-pickr
            id="end-time"
            v-model="filter.end_time"
            class="form-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              time_24hr: true,
            }"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :historyData="historyData"
      :is-loading="isLoading"
      :get-data="getData"
      :current-page="currentPage"
    />
    <Modal
      :get-history="getRedeemHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams="filter"
    />
  </b-card>
</template>

<script>
import Table from "@/components/redeem-history/Table.vue";
import Modal from "@/components/redeem-history/Modal.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    Modal,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      historyData: {},
      filter: {},
      validations: "",
      histories: [],
      columns: [],
      status: [
        { value: "success", name: "Success" },
        { value: "processing", name: "Processing" },
        { value: "draft", name: "Draft" },
        { value: "time_limit_exceeded", name: "Time Limit Exceeded" },
        { value: "business_error", name: "Business Error" },
        { value: "system_error", name: "System Error" },
      ],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/api/v1/admin/digital-reward-transactions", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.historyData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRedeemHistory() {
      this.$http
        .get("/api/v1/admin/export/history/digital-reward-redeem")
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=digital-reward-redeem")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
