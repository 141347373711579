var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Redeem History List"}},[_c('b-col',{staticClass:"p-0 mt-1 mb-1",attrs:{"cols":"12 row text-left"}},[_c('b-col',{attrs:{"cols":"col-2 md-2 sm-6 ml-1"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.form-export-modal-redeem-history",modifiers:{"form-export-modal-redeem-history":true}}],staticClass:"btn waves-effect waves-float waves-light btn-success",attrs:{"variant":"success"}},[_vm._v(" Export Redeem History ")])],1)],1),_c('b-row',{staticClass:"mb-2 justify-content-between"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status"}},[_vm._v("Status:")]),_c('v-select',{attrs:{"id":"status","label":"name","options":_vm.status,"placeholder":"-- Pilih --","reduce":function (option) { return option.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"start-time"}},[_vm._v("Start Time:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start-time","config":{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:ss',
            time_24hr: true,
          }},model:{value:(_vm.filter.start_time),callback:function ($$v) {_vm.$set(_vm.filter, "start_time", $$v)},expression:"filter.start_time"}})],1)]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"end-time"}},[_vm._v("End Time:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end-time","config":{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:ss',
            time_24hr: true,
          }},model:{value:(_vm.filter.end_time),callback:function ($$v) {_vm.$set(_vm.filter, "end_time", $$v)},expression:"filter.end_time"}})],1)])],1),_c('Table',{attrs:{"result":_vm.result,"historyData":_vm.historyData,"is-loading":_vm.isLoading,"get-data":_vm.getData,"current-page":_vm.currentPage}}),_c('Modal',{attrs:{"get-history":_vm.getRedeemHistory,"get-columns":_vm.getColumns,"history":_vm.histories,"columns":_vm.columns,"filterParams":_vm.filter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }